import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/OpenHAB_Home_Automation/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB 2 IP Camera Control",
  "path": "/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/",
  "dateChanged": "2018-09-28",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_OpenHAB.png",
  "social": "/images/Search/AU_SearchThumb_OpenHAB.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='OpenHAB 2 IP Camera Control' dateChanged='2018-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/OpenHAB_Heimautomatisierung/IP_Kamera_Kontrolle/' locationFR='/fr/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "openhab-2-ip-camera-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-2-ip-camera-control",
        "aria-label": "openhab 2 ip camera control permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB 2 IP Camera Control`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#camera-api-to-node-red-to-openhab"
        }}>{`Camera API to Node-RED to OpenHAB`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#openhab-troubleshooting"
            }}>{`OpenHAB Troubleshooting`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#items"
            }}>{`Items`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#switch"
                }}>{`Switch`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#string"
                }}>{`String`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#transformation-services"
                }}>{`Transformation Services`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#sitemap"
            }}>{`Sitemap`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#setting-up-node-red"
            }}>{`Setting up Node-RED`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#source-code"
        }}>{`Source Code`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#homeitems"
            }}>{`home.items`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#homesitemap"
            }}>{`home.sitemap`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#node-red-flow"
            }}>{`Node-RED Flow`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "camera-api-to-node-red-to-openhab",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-api-to-node-red-to-openhab",
        "aria-label": "camera api to node red to openhab permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera API to Node-RED to OpenHAB`}</h2>
    <p>{`In our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/"
      }}>{`previous tutorial`}</a>{` we already mapped our INSTAR REST API to MQTT with Node-RED. That means, that we are now able to publish a message via the MQTT protocol and trigger Node-RED to send a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`CGI Command`}</a>{` to our camera. Before we used `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/Mosquitto_Installation_on_Windows/"
      }}>{`MQTT.fx`}</a>{` to send those messages or build a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/Advanced/"
      }}>{`Dashboard in Node-RED`}</a>{` to publish messages with a press of a button.`}</p>
    <p>{`But the MQTT protocol is an IoT (Internet of Things) standard that makes you camera compatible to a wide range of IoT hardware and software home automation systems. Let us take a look how we can level this new power to integrate our IP camera into an existing smart home. Let us now use the OpenHAB interface to send commands to our camera.`}</p>
    <h3 {...{
      "id": "openhab-troubleshooting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#openhab-troubleshooting",
        "aria-label": "openhab troubleshooting permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB Troubleshooting`}</h3>
    <p>{`OpenHAB logs all important system events and errors in two log files under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./userdata/events.logs`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./userdata/openhab.log`}</code>{` in the install directory of OpenHAB (under Windows 10 this is inside the `}<em parentName="p">{`Users`}</em>{` folder) - it is a good practice to keep those two files opened in your editor when working on configuration files:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fbf51b648310269e4db46303c6f7b4c4/e9beb/OpenHAB_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABPElEQVQY003PW47bMAxG4aykRdomsSPJsnUXSV3sWE6BbqH7X0ehmQxSgK8f/sMT5upiZdLdmOKzW1wRi2dzUMpH68B5a4PyyUAxIWmftc9sMiPvd5IO0vrULt3Ycr0rscCkkCuiiI3in4IFEMqRjgbbnuqT6iGWeGN64OY0ypBqszGPQl2ZnhRNGoVCCLhBfCagADHt1FooD8wH5vbGLjcT8/U+X0Y5cCMUfix3/MD4O0OKEHOjrWE5qD49rEy6gZuOVahi8d9+DOfL/ca0UDSpvowBd4Q3XrvEcthQ+RxemPs8Gzj/up8v7IW/svtywtzxjtuOtUFugR6zoVHYjqUHocP3N8ZJv7K3VzbG3HDbqfbyQA8bq/aFz/5EUv5da+L25yAHbj9//m8ZckQojdZG5YC0L5Y+Lo3C/gNIpGauRQaPDgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fbf51b648310269e4db46303c6f7b4c4/e4706/OpenHAB_01.avif 230w", "/en/static/fbf51b648310269e4db46303c6f7b4c4/d1af7/OpenHAB_01.avif 460w", "/en/static/fbf51b648310269e4db46303c6f7b4c4/b6582/OpenHAB_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fbf51b648310269e4db46303c6f7b4c4/a0b58/OpenHAB_01.webp 230w", "/en/static/fbf51b648310269e4db46303c6f7b4c4/bc10c/OpenHAB_01.webp 460w", "/en/static/fbf51b648310269e4db46303c6f7b4c4/87ca7/OpenHAB_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fbf51b648310269e4db46303c6f7b4c4/81c8e/OpenHAB_01.png 230w", "/en/static/fbf51b648310269e4db46303c6f7b4c4/08a84/OpenHAB_01.png 460w", "/en/static/fbf51b648310269e4db46303c6f7b4c4/e9beb/OpenHAB_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fbf51b648310269e4db46303c6f7b4c4/e9beb/OpenHAB_01.png",
              "alt": "OpenHAB",
              "title": "OpenHAB",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Whenever something `}<strong parentName="p">{`just doesn't seem to work`}</strong>{`, take a look at those files.`}</p>
    <h3 {...{
      "id": "items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#items",
        "aria-label": "items permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Items`}</h3>
    <p>{`We `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/#add-items"
      }}>{`already learnt`}</a>{` how to add a dummy switch to our OpenHAB UI. Let's make this a little bit more exciting by mapping a switch to a MQTT topic - so that using the switch publishes a MQTT message that is then received by an already prepared Node-RED flow and triggers sending a CGI command (activate or deactivate Motion Detection Area 1) to our camera. And to get a feedback, whether the command was received or not, we can additionally add a text output to the UI.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/36cf5cd3520695eb5172e68bc272f601/e9beb/OpenHAB_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABKklEQVQY0yWPWW7cMBAF5yZG7JEsUSIlUlx64+KRPXAQBMj9T2NQAfq3XlXfEomLPM77fdqH2SxbUIvfbDA2LSYcsRgHSod1i6M6RuWnxQ+zm3R4X9zNE39+/+X63D2NyiodEzFWwY9+qVKsDE1SLYeQZbRMO6AOadL+Nm4o7RmpGQf3aVMmRCS44FjZNXaFj1JSy1D4/2IgWXeYVn87IOf2tJ6XLVxwjEBYGZtQE2wMtYfEWjYkk8AA6AjrcZld/bZQXu7qdVzf3k2Hkagy9VRxtZv9ZcbS+7F1s7bYzegiujS9zb+G5XXUHU6EhbFdn1futYVTLZa4mxNonxYbO3w6+nf++W3CMG2DssoEEJZHlq9MZw5n9g8JHwXPxo8sn1m+ChRxSWbtfwCiUVieN2dpJQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36cf5cd3520695eb5172e68bc272f601/e4706/OpenHAB_02.avif 230w", "/en/static/36cf5cd3520695eb5172e68bc272f601/d1af7/OpenHAB_02.avif 460w", "/en/static/36cf5cd3520695eb5172e68bc272f601/b6582/OpenHAB_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/36cf5cd3520695eb5172e68bc272f601/a0b58/OpenHAB_02.webp 230w", "/en/static/36cf5cd3520695eb5172e68bc272f601/bc10c/OpenHAB_02.webp 460w", "/en/static/36cf5cd3520695eb5172e68bc272f601/87ca7/OpenHAB_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36cf5cd3520695eb5172e68bc272f601/81c8e/OpenHAB_02.png 230w", "/en/static/36cf5cd3520695eb5172e68bc272f601/08a84/OpenHAB_02.png 460w", "/en/static/36cf5cd3520695eb5172e68bc272f601/e9beb/OpenHAB_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/36cf5cd3520695eb5172e68bc272f601/e9beb/OpenHAB_02.png",
              "alt": "OpenHAB",
              "title": "OpenHAB",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The code for this construct looks like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`Switch EnableAreaOne `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt="`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`mosquitto`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`openhab/alarm/setmdattr/enablearea1/enable/set`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`command`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token important"
          }}>{`*:MAP(mqtt-binding.map)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`<`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`mosquitto`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`openhab/alarm/setmdattr/enablearea1/enable/get`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`JSONPATH($.val)`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`"`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

String EnableAreaOneState "MD Area 1 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`%s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`" <motion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt="<`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`mosquitto`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`openhab/alarm/setmdattr/enablearea1/enable/get`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`JSONPATH($.val)`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`"`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h4 {...{
      "id": "switch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#switch",
        "aria-label": "switch permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Switch`}</h4>
    <p>{`Let's break down what is happening here. First we choose an `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/concepts/items.html#items"
      }}>{`item type`}</a>{` - in this case a `}<strong parentName="p">{`Switch`}</strong>{` - and gave it a name `}<strong parentName="p">{`EnableAreaOne`}</strong>{`, so we can reference it from our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation#sitemap"
      }}>{`sitemap`}</a>{`. We then used the MQTT binding to bind a MQTT topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mosquitto:openhab/alarm/setmdattr/enablearea1/enable/set`}</code>{` (`}<strong parentName="p">{`mosquitto`}</strong>{` is the name of MQTT broker we `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/#connect-the-mqtt-server-to-openhab2"
      }}>{`set up earlier`}</a>{`) to the `}<em parentName="p">{`ON`}</em>{` and `}<em parentName="p">{`OFF`}</em>{` state of our switch. This is represented by the first element that starts with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`>`}</code>{`, which means that it is the `}<em parentName="p">{`outgoing message`}</em>{`. The command send by the switch, over the MQTT service, is either `}<strong parentName="p">{`ON`}</strong>{` or `}<strong parentName="p">{`OFF`}</strong>{` - since we our Node-RED flow requires a JSON messages, we are using the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation#transformation-service"
      }}>{`MAP Transformer`}</a>{` to turn this command into the compliant message (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"ON"}`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"OFF"}`}</code>{`).`}</p>
    <p>{`For incoming messages - symbolized by the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<`}</code>{` in the second part of the binding - subscribes our Switch to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mosquitto:openhab/alarm/setmdattr/enablearea1/enable/get`}</code>{` which is also defined in our Node-RED flow and gives us the current state of Motion Detection area one. The incoming message here is formatted in JSON and needs to be transformed to a string value. We use the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation#transformation-service"
      }}>{`JSONPath Transoformer`}</a>{` to give us the value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`val`}</code>{` (in the incoming message `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"ON"}`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"OFF"}`}</code>{`) which is setting the state of our button either in the `}<strong parentName="p">{`ON`}</strong>{` or `}<strong parentName="p">{`OFF`}</strong>{` position.`}</p>
    <h4 {...{
      "id": "string",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#string",
        "aria-label": "string permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`String`}</h4>
    <p>{`The Text-Output follows the same structure. We assign the proper type, in this case a `}<strong parentName="p">{`String`}</strong>{` and give it a name (`}<strong parentName="p">{`EnableAreaOneState`}</strong>{`), to be able to reference it in our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation#sitemap"
      }}>{`sitemap`}</a>{`. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"MD Area 1 [%s]"`}</code>{` prefixes the message output with `}<em parentName="p">{`MD Area 1`}</em>{` - see `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/configuration/items.html#state-presentation"
      }}>{`OpenHAB documentation`}</a>{` for more examples of the Java formatter class syntax. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<motion>`}</code>{` adds the `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/configuration/iconsets/classic/"
      }}>{`Motion Detection Icon`}</a>{` that will be displayed in front of the text output. The MQTT call is identical to the incoming message for our switch - it takes the JSON expression send over MQTT by our Node-RED flow and extracts the value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`val`}</code>{` to be displayed as the text output.`}</p>
    <h4 {...{
      "id": "transformation-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#transformation-services",
        "aria-label": "transformation services permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transformation Services`}</h4>
    <p>{`The OpenHAB `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/configuration/transformations.html#transformation-services"
      }}>{`Transformations`}</a>{` are used to translate data from a cluttered or technical raw value to a processed or human-readable representation. They are often useful, to interpret received Item values, like sensor readings or state variables, and to translate them into a human-readable or better processible format. You can install them through the OpenHAB PaperUI under `}<a parentName="p" {...{
        "href": "http://localhost:8080/paperui/index.html#/extensions?tab=transformation"
      }}>{`this link`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8e2ce0bbbf0f604ca6a05042c2ef8498/e9beb/OpenHAB_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABJ0lEQVQY042Ou04CURBA9zNEjRhj/CtBiMZ/sULFB4VWljY8AokWJpRWNBZqYFcNrvvyLvfCPudhLkZLdDKZnMzMyYwhxWcUeCpn7J5SeRX3Nml7CatFrBSpVMDKGu2sUHkZdjc0lwpUXaf9LV1LBSOKYyJkZny6T68PoHNCrRp2z6l3Rs0atOvYOsRmLe81oFPH9hHeXODdFd5eYvvYUEplaYqIQAzMQJQgxUizHFPixWGYpmnbtuM4Uk6YWYbh+3isJmEY+PFUMQBDrhPhh2HOumlIKZMkAQBEYOYkmoW+q6TWQyGyLFt0+ZeI9JcflvMwsNSLJUZWODIzx/2e/SHjfGP4HPSH+VQJX4iJlJnr/esyopbNR7c/CJQ99l7fIs8nz18gfwH2jbAqYCA0owAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e2ce0bbbf0f604ca6a05042c2ef8498/e4706/OpenHAB_04.avif 230w", "/en/static/8e2ce0bbbf0f604ca6a05042c2ef8498/d1af7/OpenHAB_04.avif 460w", "/en/static/8e2ce0bbbf0f604ca6a05042c2ef8498/b6582/OpenHAB_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8e2ce0bbbf0f604ca6a05042c2ef8498/a0b58/OpenHAB_04.webp 230w", "/en/static/8e2ce0bbbf0f604ca6a05042c2ef8498/bc10c/OpenHAB_04.webp 460w", "/en/static/8e2ce0bbbf0f604ca6a05042c2ef8498/87ca7/OpenHAB_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e2ce0bbbf0f604ca6a05042c2ef8498/81c8e/OpenHAB_04.png 230w", "/en/static/8e2ce0bbbf0f604ca6a05042c2ef8498/08a84/OpenHAB_04.png 460w", "/en/static/8e2ce0bbbf0f604ca6a05042c2ef8498/e9beb/OpenHAB_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8e2ce0bbbf0f604ca6a05042c2ef8498/e9beb/OpenHAB_04.png",
              "alt": "OpenHAB",
              "title": "OpenHAB",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<strong parentName="p">{`JSONPath Transformer`}</strong>{` works out of the box. For the `}<strong parentName="p">{`MAP Transformer`}</strong>{` you need to add a `}<em parentName="p">{`.map`}</em>{` in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./conf/transform`}</code>{` referencing the values you want to map.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a1ce1ce7ba4fcee2642a5c1a80ada42c/e9beb/OpenHAB_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.69565217391303%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABgklEQVQoz5XS226cMBAGYJ4k3WUBY49P2Iw5m0MMTbNR+/5vU8GqUZuqVZD+C998mvHMRAZ7WdRxxq8pvxERZ5JJwTUyiaBkgbXQ1Y0WVFUgy4QZ261+efXLq0IfNUMVXr5hM+qyBYVxxjNmmKyaIfTj19YHadqMGQI252XOSwL2/R1x64Z563zAZtyLEPnArV/ncK/amUlUtuWqIlA+zHsihs+uXzsfbD0a7EG5DCzoup9f+mlPgb5AL0yXC0dl9XuiXJTDvPbTWreTdV2c8pTqHY9b6zfQNeVI9jpIhfuQqPLL8/0HV44Km3ObUk3AUOFAOVBV/lerf7Sdcls0E2HqkrBHUlp8GMw/cZwJ0YR+DBkUlwSuKRwY/89+Tds0Q7hr+1j1SWxduW4h5/Ypzg/PTuAM9LGkjoA+XfkpAYqzXzau8ZKwcxiK2m/fC9s8yp7DVJbttGlbf7nRa8rO/TkmEnDE2idUp1Ql+X7bn5HHkTBjxrdle8NmpqI8Lsx+Ev8EoLixxKWPvSgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1ce1ce7ba4fcee2642a5c1a80ada42c/e4706/OpenHAB_03.avif 230w", "/en/static/a1ce1ce7ba4fcee2642a5c1a80ada42c/d1af7/OpenHAB_03.avif 460w", "/en/static/a1ce1ce7ba4fcee2642a5c1a80ada42c/b6582/OpenHAB_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a1ce1ce7ba4fcee2642a5c1a80ada42c/a0b58/OpenHAB_03.webp 230w", "/en/static/a1ce1ce7ba4fcee2642a5c1a80ada42c/bc10c/OpenHAB_03.webp 460w", "/en/static/a1ce1ce7ba4fcee2642a5c1a80ada42c/87ca7/OpenHAB_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1ce1ce7ba4fcee2642a5c1a80ada42c/81c8e/OpenHAB_03.png 230w", "/en/static/a1ce1ce7ba4fcee2642a5c1a80ada42c/08a84/OpenHAB_03.png 460w", "/en/static/a1ce1ce7ba4fcee2642a5c1a80ada42c/e9beb/OpenHAB_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a1ce1ce7ba4fcee2642a5c1a80ada42c/e9beb/OpenHAB_03.png",
              "alt": "OpenHAB",
              "title": "OpenHAB",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can see in the screenshot above that when the Switch is switched into the `}<strong parentName="p">{`ON Position`}</strong>{` and the `}<strong parentName="p">{`ON`}</strong>{` command is issued, it is then transformed into the JSON expression `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"ON"}`}</code>{` that we then can use in our Node-RED flow.`}</p>
    <h3 {...{
      "id": "sitemap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sitemap",
        "aria-label": "sitemap permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sitemap`}</h3>
    <p>{`To have our Switch and String output displayed, we now have to add it to the home `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/configuration/sitemaps.html#sitemaps"
      }}>{`sitemap`}</a>{`. The sitemap file can be found in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./conf/sitemaps`}</code>{`: `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/82a23c07930375539e5a390e5d7889c3/e9beb/OpenHAB_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABt0lEQVQoz2WSWY7bMBAFdZJkPLIWkk2RFPddtLwMMrlA7n+RQPYgSBCgf6vrdeN1PtWQL9Jk4LaflhEzKozk26rjagvVDkvPXMCrmakB5hDVmAfld2FqB4LG0kyoQsd+ov244MUS5oE6JgJTYVGeKgfC4cUiZtBiEJgRyYmobl6zL/dYb9JmKuyIBaJmBkmoctxfQ7s8rj9/1I9bNS4zF5hzVFoi9AyqI8Kn7R7r3cYLcDsgjo/dagRFQbk1Op9LCiVFYyMRlqwGhCXcHPDqSmkPYAZROWL+B55ACaqrrrXtjz3fLtn4wuxhBmkxe5rB7Tpsp4G8nfHbGZ/nBS/H1gnUAtrJ5EKuOdYStQ2EH4GJMF9mylWq7XuPTwM5DeQ8LX+bN11e5vueU67cRh481UdstOiux0LXB5fuNML/Zq+ST6Wm0EqKIVLh8FNLxBMeiHTtU7v0PsBpeMH2ZeZUF5VTbdcttRqtL9xF5h0o83UzoqJd936Eb+/z2xn30z9wMiXX7drSreUYE1398W1pYDWIqu4O6692+9S5zuzZMPGKjameQI1EjngdsByQHLGcyNGN+TWgfgNfsoL+L2nJwgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/82a23c07930375539e5a390e5d7889c3/e4706/OpenHAB_05.avif 230w", "/en/static/82a23c07930375539e5a390e5d7889c3/d1af7/OpenHAB_05.avif 460w", "/en/static/82a23c07930375539e5a390e5d7889c3/b6582/OpenHAB_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/82a23c07930375539e5a390e5d7889c3/a0b58/OpenHAB_05.webp 230w", "/en/static/82a23c07930375539e5a390e5d7889c3/bc10c/OpenHAB_05.webp 460w", "/en/static/82a23c07930375539e5a390e5d7889c3/87ca7/OpenHAB_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/82a23c07930375539e5a390e5d7889c3/81c8e/OpenHAB_05.png 230w", "/en/static/82a23c07930375539e5a390e5d7889c3/08a84/OpenHAB_05.png 460w", "/en/static/82a23c07930375539e5a390e5d7889c3/e9beb/OpenHAB_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/82a23c07930375539e5a390e5d7889c3/e9beb/OpenHAB_05.png",
              "alt": "OpenHAB",
              "title": "OpenHAB",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`sitemap home label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"INSTAR - SmartOffice"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

        Frame label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Detection Areas"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                Switch item=EnableAreaOne label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Enable Area 1"`}</span>{`
                Text item=EnableAreaOneState

        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The `}<strong parentName="p">{`Sitemap Label`}</strong>{` will be displayed in the Navigation Bar above the UI and the `}<strong parentName="p">{`Frame`}</strong>{` groups our items in a Material Design Card. Go and copy the source code for `}<a parentName="p" {...{
        "href": ""
      }}>{`home.items`}</a>{` and `}<a parentName="p" {...{
        "href": ""
      }}>{`home.sitemap`}</a>{` below (it adds a few more switches and text outputs to the UI) and open the OpenHAB Basic-UI under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8080/basicui/app`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/87481eb230b2f4e4782db1e6fa6894ca/e9beb/OpenHAB_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABWUlEQVQoz5WRS07DQBBEfSngACz4CbFhQzZwP1gRSJBIIpA4RwggkPKzZzyZ7uoeO0G2UWTlA6JUC0tT1f1mHO2f355eto4bd4cXzbOr1kmjuXNwvXv4t/eObqJ2t//QK/z49NZ7+eg8v7e7g3Z30Krcea0+2mu+7/QjgRM41VkIWPxPefTp45nzs5nP8nmW5UsTMTMmk6lznrmYm9dOsywHJJqKA4OIIDKvSURUNU1TLgUghFAPqGo0EisM7z0R1ZmqsrWWmalUCKEeKMswKAh5sVisbzbGMLOKrJz+bB4jrTYz83q5vllVV8tDWJRlAJs3V1XmDXcecsLly27FJvoF2yqkHL0NG1uxxyhfm2gjdlEmrtA2YcMwFYEVKgAiYowhItmKLTaIAlDVld8YQnDOSaFiUJbn9UAIIfqyY2tsHMdJknBNaZo650ajUVIqThLnHIBlwHv/DchmQCxep6txAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87481eb230b2f4e4782db1e6fa6894ca/e4706/OpenHAB_06.avif 230w", "/en/static/87481eb230b2f4e4782db1e6fa6894ca/d1af7/OpenHAB_06.avif 460w", "/en/static/87481eb230b2f4e4782db1e6fa6894ca/b6582/OpenHAB_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/87481eb230b2f4e4782db1e6fa6894ca/a0b58/OpenHAB_06.webp 230w", "/en/static/87481eb230b2f4e4782db1e6fa6894ca/bc10c/OpenHAB_06.webp 460w", "/en/static/87481eb230b2f4e4782db1e6fa6894ca/87ca7/OpenHAB_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87481eb230b2f4e4782db1e6fa6894ca/81c8e/OpenHAB_06.png 230w", "/en/static/87481eb230b2f4e4782db1e6fa6894ca/08a84/OpenHAB_06.png 460w", "/en/static/87481eb230b2f4e4782db1e6fa6894ca/e9beb/OpenHAB_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/87481eb230b2f4e4782db1e6fa6894ca/e9beb/OpenHAB_06.png",
              "alt": "OpenHAB",
              "title": "OpenHAB",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "setting-up-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#setting-up-node-red",
        "aria-label": "setting up node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up Node-RED`}</h3>
    <p>{`Now we just have to import the (rather long) `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/#node-red-flow"
      }}>{`Node-RED export`}</a>{` - check out the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/"
      }}>{`Node-RED Introduction`}</a>{` in case that you missed it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/44918e336c89728560fa6d71830ecf87/ce92a/Node-RED_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABsElEQVQoz42PS4+bMBSF+f+/pLsuumhHqtJRm2lCJ00CCQEM5mUetnFMIIHhZbuaqTTqqsq3OLqL80nnasCGruXxS51Qfr7clJKzuBcNmLr1/AMzvvN3QbIbh5sQ6l45ShCAwTD0NYfH5y8YRUrdLSP4KwJrMYuXduD0OvWzUlLI/yivb03za2rmUT+Y64xXny0L4LzMS5zQaZjkG+/tv/dbKiGVUkpKqaVpVlKGKXtaG0cTJO4q93VK9jwx8PaBxnrofczSXRLrlnW0rJO9WXqnPWBVNwzaylhtThsxzwyRIsiV7MXcCtHRJLaXy9TVPf1D4Cxs5yuEDiZle0FNXURV/TKOmn+yEuAWdfMtjGFZ0pQRxKdhFEpdr2eWGZylJNe79oox7bpO/YPmed/jSKfs8ttwgjDDiUPCbcW9mvnMejzDTZo84GKLkB7HrmEcIujZq4WfZVlz004OsB0wDgOyw+AApWinvux7VlcFWP9E1j4wP8HDg+s9+v62KCjnpCm9rDrnzVXLI8DziNTNUxSHJeOkYsVlHichVV23hb8ksZkGi5Ic8zxDCEkp32f/AYsAX8Hu873+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/44918e336c89728560fa6d71830ecf87/e4706/Node-RED_01.avif 230w", "/en/static/44918e336c89728560fa6d71830ecf87/d1af7/Node-RED_01.avif 460w", "/en/static/44918e336c89728560fa6d71830ecf87/7f308/Node-RED_01.avif 920w", "/en/static/44918e336c89728560fa6d71830ecf87/e1c99/Node-RED_01.avif 1380w", "/en/static/44918e336c89728560fa6d71830ecf87/ebfc5/Node-RED_01.avif 1459w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/44918e336c89728560fa6d71830ecf87/a0b58/Node-RED_01.webp 230w", "/en/static/44918e336c89728560fa6d71830ecf87/bc10c/Node-RED_01.webp 460w", "/en/static/44918e336c89728560fa6d71830ecf87/966d8/Node-RED_01.webp 920w", "/en/static/44918e336c89728560fa6d71830ecf87/445df/Node-RED_01.webp 1380w", "/en/static/44918e336c89728560fa6d71830ecf87/97f04/Node-RED_01.webp 1459w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/44918e336c89728560fa6d71830ecf87/81c8e/Node-RED_01.png 230w", "/en/static/44918e336c89728560fa6d71830ecf87/08a84/Node-RED_01.png 460w", "/en/static/44918e336c89728560fa6d71830ecf87/c0255/Node-RED_01.png 920w", "/en/static/44918e336c89728560fa6d71830ecf87/b1001/Node-RED_01.png 1380w", "/en/static/44918e336c89728560fa6d71830ecf87/ce92a/Node-RED_01.png 1459w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/44918e336c89728560fa6d71830ecf87/c0255/Node-RED_01.png",
              "alt": "OpenHAB",
              "title": "OpenHAB",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Opening the Node-RED dashboard will show you the following UI:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/084ffbe37c5e76eab961faa05d2f51fb/e9beb/Node-RED_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADx0lEQVQ4y13P+08TBwAH8PsHTIAZ+sgi4EQRF0pf17ter5xgr6VvSunBVnqUV2l1AyYCA6mFIqworFBreFgQF0WhSpECWWG1CLKALYRuJUvcy/gDCZlmIyH648LIHuybz6/f5PsF2FwBRyjSGU1a0kSaa1s6uhvaHA22Lz5rsVsuXS4wmg/o/lZAmsBs5WmOMJ0jAEJW7Ur7x2+89r3J9l2v7Y+Jfb9P2Ha9rW+nOt497jzwdqrjwN6kPdpdFWrOX27JAyIN2euN555+AodMzEUTI2TKfFqV+a2FuWJhLpkYi5X7QpUZS2bmMwvrmYW1YmGHa6BInWCtlg8wmGwhinIh5AyL9yEbOsBFUBARZoBIKhM+ydqXyeHxeBAXhCAIYrA4JzNY759IAxAEwXE8JycbhiAeCHK5YBYMFooEKpSjQtkqlK0WcgmRIE+pyNNoZDKZXC6XSqUEQWAYBiB8fq5EwodhPh8WIDALhC4os17ZVC869D9dM/7YZfy5TRu5iC/P+UJLywsL80+eBAOBQCwW6+zsBGA+ck4kBnkQD4J5MJ/BZJUZS9YWg9MTD/wPxx97HywG5mYnvXOzs7Mzsz6fz+/3P5qcXF1bs9lsQA4mLFTLxRgqykKkGF8shDEMUxcQYpkCl8pxmVyiUKm1OoVCqVQqxWKxRCLBcVyTn4+iKGBRnw12Vd2/bOglz359SXHXIroo5QRLmN+YhcHz2cHzOcFy0Fd45t5A31d3x26PjIyOjno8nulpf11dHZCcdOxESvLxlOTjyUmpH6TQqBSyQL2zNr81//CHhUdbAe/L5ZnozL3NyPNo9Lv19fXNzc1wOLy9ve10OoHERMo/KBRqQkICgw2W1TZoisvyDeX5xWUfVV4wWGoMZIlerycIoqioSKcjKioqJBIJQPs3VDqd+l4iBUtPcivTe/LYPRpOjxbslqU7ctOu1Ne2WK80NjY2NX1eX1/vcDj0ej1A/U/odNqRuASDFNvz3/j1/vVXXufL8S9f+/pe3G7biqxGv48dzH4eDu/s7LhcrkNlGo2eEB+PZouuD481OXqbr7maHL1tNzytvYNtV6+22+02m621tdVqtTqdTpIkD5ep1KOJFFbqMXNWupF/yoicMiJpRii1XHBar9MUG0idTkcQhFarLS0tFYnww7NptCPxR0tyuO9uffqbp/7NaNPr0ebdoZpfusit1eVobGtjYyMajUYi63/N7vvfZ3p8XBwuyZ2Y8rsGhtxDw67+oTtj44Mjd255hgcHB903b/b3D7jd7gmvt7q6+k9yRaUmgWNlpwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/084ffbe37c5e76eab961faa05d2f51fb/e4706/Node-RED_02.avif 230w", "/en/static/084ffbe37c5e76eab961faa05d2f51fb/d1af7/Node-RED_02.avif 460w", "/en/static/084ffbe37c5e76eab961faa05d2f51fb/b6582/Node-RED_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/084ffbe37c5e76eab961faa05d2f51fb/a0b58/Node-RED_02.webp 230w", "/en/static/084ffbe37c5e76eab961faa05d2f51fb/bc10c/Node-RED_02.webp 460w", "/en/static/084ffbe37c5e76eab961faa05d2f51fb/87ca7/Node-RED_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/084ffbe37c5e76eab961faa05d2f51fb/81c8e/Node-RED_02.png 230w", "/en/static/084ffbe37c5e76eab961faa05d2f51fb/08a84/Node-RED_02.png 460w", "/en/static/084ffbe37c5e76eab961faa05d2f51fb/e9beb/Node-RED_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/084ffbe37c5e76eab961faa05d2f51fb/e9beb/Node-RED_02.png",
              "alt": "OpenHAB",
              "title": "OpenHAB",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in your cameras IP address (e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.1.116`}</code>{`) and your admin login - this will make the login available to all Node-RED flows (IP, username and password are set as global variables). When opening up both UI - the Node-RED Dashboard and the OpenHAB Basic-UI - you should see that they operate in sync:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/314cdde536b2244f01be991ca971e791/openHAB-Node-RED.gif",
        "alt": "OpenHAB"
      }}></img></p>
    <p>{`And the settings accurately reflect the camera configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/84c3095db0cbcaf19f95f36fc6511983/05d4a/Node-RED_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB7ElEQVQozzWQ3UuTcRTHn3+qQAp6I9SVQYhp6aI2l2+rpKCuoykVtKk3CnXlRWLQxQhiIFHKwL0/OZjl2vvc5ip1m3ue3+93fi8nHp768rk6nA+c89Ver0dfrH/zvUvNWehzazYp35r+6n1yfvGLf1kPrCTnF+K+wH/8sef+mC8Q19Jv3n51DERHnfodV8Yz8WNyes/7sDY79cG9sPgy0/hTkZIoJRARuFSKIyr8F6XtLC2FTp3e6uvXB4e+jzlzd127o87YtYHgmb6P3me5RsWkBIAj4uERabVOuoYhhERESplWWF39OXY7MXwzeWOkMDFVezAbuT746cKlUE9P2DtT3d/nAJxbMgAnhFJKpbRkzrmWXl7ZGRouTnuzbk/+3mRl5v6m4+rG5d7Q2XPhR4/LlRIxDABARJNAp3PS7XZtmTGmFTe39CdPw44r+sitrGt8zzX+ubd/4/zFbbcnGwzW8wVOKQCXUgrOTcMwTZMLoZSy5INqtV0o1CKJg8h2I576FU/8TiSakehhJtNqNsulYqfdNgxDSgkAlFJCCAAIIQBAOzo+5ohdgUxaNZoSpUKhEBCBsXytQoExxhBRSskYs1+wo3VKaaynlVJSSiWlUkpwkSh1y8nddiyeq1dMRggh1lwI0zTtK+z9vytgsXK0AbLRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84c3095db0cbcaf19f95f36fc6511983/e4706/Node-RED_03.avif 230w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/d1af7/Node-RED_03.avif 460w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/7f308/Node-RED_03.avif 920w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/e1c99/Node-RED_03.avif 1380w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/76ea5/Node-RED_03.avif 1840w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/13e6f/Node-RED_03.avif 1861w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/84c3095db0cbcaf19f95f36fc6511983/a0b58/Node-RED_03.webp 230w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/bc10c/Node-RED_03.webp 460w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/966d8/Node-RED_03.webp 920w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/445df/Node-RED_03.webp 1380w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/78de1/Node-RED_03.webp 1840w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/2ce43/Node-RED_03.webp 1861w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84c3095db0cbcaf19f95f36fc6511983/81c8e/Node-RED_03.png 230w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/08a84/Node-RED_03.png 460w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/c0255/Node-RED_03.png 920w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/b1001/Node-RED_03.png 1380w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/161ec/Node-RED_03.png 1840w", "/en/static/84c3095db0cbcaf19f95f36fc6511983/05d4a/Node-RED_03.png 1861w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/84c3095db0cbcaf19f95f36fc6511983/c0255/Node-RED_03.png",
              "alt": "OpenHAB",
              "title": "OpenHAB",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "source-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#source-code",
        "aria-label": "source code permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Source Code`}</h2>
    <h3 {...{
      "id": "homeitems",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#homeitems",
        "aria-label": "homeitems permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`home.items`}</h3>
    <p>{`Copy the following lines into `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./conf/items/home.items`}</code>{`. In addition to the description above, we added Switches for all 4 Motion Detection areas and additional switches to change the sensitivity of the Motion Detection:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Detection Areas`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// // Activate`}</span>{`

Switch EnableAreaOne `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`">[mosquitto:openhab/alarm/setmdattr/enablearea1/enable/set:command:*:MAP(mqtt-binding.map)],<[mosquitto:openhab/alarm/setmdattr/enablearea1/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
String EnableAreaOneState `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"MD Area 1 [%s]"`}</span>{` <motion> `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<[mosquitto:openhab/alarm/setmdattr/enablearea1/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

Switch EnableAreaTwo `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`">[mosquitto:openhab/alarm/setmdattr/enablearea2/enable/set:command:*:MAP(mqtt-binding.map)],<[mosquitto:openhab/alarm/setmdattr/enablearea2/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
String EnableAreaTwoState `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"MD Area 2 [%s]"`}</span>{` <motion> `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<[mosquitto:openhab/alarm/setmdattr/enablearea2/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

Switch EnableAreaThree `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`">[mosquitto:openhab/alarm/setmdattr/enablearea3/enable/set:command:*:MAP(mqtt-binding.map)],<[mosquitto:openhab/alarm/setmdattr/enablearea3/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
String EnableAreaThreeState `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"MD Area 3 [%s]"`}</span>{` <motion> `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<[mosquitto:openhab/alarm/setmdattr/enablearea3/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

Switch EnableAreaFour `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`">[mosquitto:openhab/alarm/setmdattr/enablearea4/enable/set:command:*:MAP(mqtt-binding.map)],<[mosquitto:openhab/alarm/setmdattr/enablearea4/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
String EnableAreaFourState `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"MD Area 4 [%s]"`}</span>{` <motion> `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<[mosquitto:openhab/alarm/setmdattr/enablearea4/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// // Sensitivity`}</span>{`

Switch AreaOneHighSense (MDNotificationsControlGroup) `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`">[mosquitto:openhab/alarm/setmdattr/area1/highsensitivity/enable/set:command:*:MAP(mqtt-binding.map)],<[mosquitto:openhab/alarm/setmdattr/area1/highsensitivity/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
String AreaOneHighSenseState `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"High Sensitivity Area 1 [%s]"`}</span>{` <motion> (MDNotificationsControlGroup) `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<[mosquitto:openhab/alarm/setmdattr/area1/highsensitivity/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

Switch AreaTwoHighSense (MDNotificationsControlGroup) `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`">[mosquitto:openhab/alarm/setmdattr/area2/highsensitivity/enable/set:command:*:MAP(mqtt-binding.map)],<[mosquitto:openhab/alarm/setmdattr/area2/highsensitivity/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
String AreaTwoHighSenseState `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"High Sensitivity Area 2 [%s]"`}</span>{` <motion> (MDNotificationsControlGroup) `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<[mosquitto:openhab/alarm/setmdattr/area2/highsensitivity/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

Switch AreaThreeHighSense (MDNotificationsControlGroup) `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`">[mosquitto:openhab/alarm/setmdattr/area3/highsensitivity/enable/set:command:*:MAP(mqtt-binding.map)],<[mosquitto:openhab/alarm/setmdattr/area3/highsensitivity/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
String AreaThreeHighSenseState `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"High Sensitivity Area 3 [%s]"`}</span>{` <motion> (MDNotificationsControlGroup) `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<[mosquitto:openhab/alarm/setmdattr/area3/highsensitivity/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

Switch AreaFourHighSense (MDNotificationsControlGroup) `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`">[mosquitto:openhab/alarm/setmdattr/area4/highsensitivity/enable/set:command:*:MAP(mqtt-binding.map)],<[mosquitto:openhab/alarm/setmdattr/area4/highsensitivity/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
String AreaFourHighSenseState `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"High Sensitivity Area 4 [%s]"`}</span>{` <motion> (MDNotificationsControlGroup) `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`mqtt=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<[mosquitto:openhab/alarm/setmdattr/area4/highsensitivity/enable/get:state:JSONPATH($.val)]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <h3 {...{
      "id": "homesitemap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#homesitemap",
        "aria-label": "homesitemap permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`home.sitemap`}</h3>
    <p>{`Copy the following lines into `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./conf/sitemaps/home.sitemap`}</code>{`. In addition to the description above, we added Switches for all 4 Motion Detection areas and additional switches to change the sensitivity of the Motion Detection:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`sitemap home label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"INSTAR - SmartOffice"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

        Frame label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Detection Areas"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                Switch item=EnableAreaOne label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Enable Area 1"`}</span>{`
                Text item=EnableAreaOneState
                Switch item=AreaOneHighSense label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Set High Sensitivity"`}</span>{`
                Text item=AreaOneHighSenseState

                Switch item=EnableAreaTwo label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Enable Area 2"`}</span>{`
                Text item=EnableAreaTwoState
                Switch item=AreaTwoHighSense label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Set High Sensitivity"`}</span>{`
                Text item=AreaTwoHighSenseState

                Switch item=EnableAreaThree label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Enable Area 3"`}</span>{`
                Text item=EnableAreaThreeState
                Switch item=AreaThreeHighSense label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Set High Sensitivity"`}</span>{`
                Text item=AreaThreeHighSenseState

                Switch item=EnableAreaFour label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Enable Area 4"`}</span>{`
                Text item=EnableAreaFourState
                Switch item=AreaFourHighSense label=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Set High Sensitivity"`}</span>{`
                Text item=AreaFourHighSenseState

        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3 {...{
      "id": "node-red-flow",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#node-red-flow",
        "aria-label": "node red flow permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Flow`}</h3>
    <p>{`Copy the following JSON block and use the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/Introduction/"
      }}>{`Node-RED Import`}</a>{` to import it into your Node-RED Flow:`}</p>
    <p><a parentName="p" {...{
        "href": "/en/de/Node-RED_Flows/nodered-openhab-camera-control.json"
      }}>{`Node-RED Flow Download`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      